<template>
  <div>
    <van-nav-bar title="登录" left-text="返回" right-text="注册" left-arrow @click-left="back" @click-right="goRegister" />
    <van-form @submit="submitFn" class="form">
      <van-field v-model="username" name="username" label="用户名" placeholder="请填写用户名" :rules="[{ required: true }]" />
      <van-field v-model="password" type="password" name="password" label="密码" placeholder="请填写密码" :rules="[{ required: true }]" />
      <div class="btn">
        <van-button class="login" block round native-type="submit">登录</van-button>
        <van-button block round @click="back">返回</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { login } from '@/api/user';
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: ''
    };
  },
  methods: {
    // 登录
    async submitFn(values) {
      try {
        const { code, data } = (await login(values)) || {};
        if (code == 200) {
          localStorage.setItem('userInfo', JSON.stringify(data));
          this.$router.push({
            path: '/home'
          });
        } else if (code == 401) {
          Notify({
            type: 'danger',
            message: '用户名或密码错误'
          });
        }
      } catch (error) {}
    },
    // 返回
    back() {
      this.$router.push({
        name: 'mine'
      });
    },
    // 注册
    goRegister() {
      this.$router.push({
        name: 'register'
      });
    }
  }
};
</script>

<style scoped lang='scss'>
.form {
  padding: 10px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  .login {
    margin-right: 10px;
    padding: 12px 6px;
    border: 1px solid #000;
    background-color: #000;
    border-radius: 22px;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    word-break: break-word;
  }
}
</style>